<template>
  <div class="table-responsive">
    <b-table
      responsive
      class="table table-head-custom table-vertical-center"
      :items="pageOfItems"
      :fields="fields"
      :busy="isLoading"
      stacked="sm"
      hover
      show-empty
    >
      <!-- Empty table -->
      <template #empty>
        <EmptyTable message="Não existem movimentações." />
      </template>

      <!-- Busy table -->
      <template #table-busy>
        <BusyTable message="Aguarde enquanto carregamos suas transações." />
      </template>

      <!-- Date -->
      <template #cell(date)="row">
        <span>
          <div class="font-weight-bolder text-primary mb-0 font-size-lg">
            {{ $moment(row.item.date).format('DD/MM/YYYY') }}
          </div>
          <div class="text-muted">{{ $moment(row.item.date).format('hh:mm:ss') }}</div>
        </span>
      </template>

      <template #cell(type)="row">
        <div class="d-flex align-items-center">
          <div class="symbol symbol-40 symbol-light mr-5">
            <span class="symbol-label">
              <i class="fa-2x fas text-primary" :class="row.item.icon"></i>
            </span>
          </div>
          <div>
            <span href="#" class="text-dark-75 font-weight-bolder mb-1">
              {{ row.item.ecosystem_movement_type_name }}
            </span>
          </div>
        </div>
      </template>

      <template #cell(object_from_to)="row">
        <div class="d-flex align-items-center">
          <div v-if="row.item.operation_type == 'T'" class="d-inline-flex align-items-center">
            <span class="symbol symbol-40 mr-2">
              <div
                v-if="JSON.parse(row.item.object_from_to).avatar"
                class="symbol-label"
                :style="'background-image:url(' + JSON.parse(row.item.object_from_to).avatar + ')'"
              ></div>
              <div v-else class="symbol-label">
                <img src="/media/svg/avatars/001-boy.svg" class="h-75 align-self-end" alt="" />
              </div>
            </span>
            <div class="d-flex flex-column text-left">
              <span class="font-weight-bold font-size-sm text-info">
                {{ nameLastname(JSON.parse(row.item.object_from_to).name) }}
              </span>
              <span class="font-weight-bolder font-size-sm">{{ JSON.parse(row.item.object_from_to).email }}</span>
            </div>
          </div>
          <div v-else class="d-inline-flex align-items-center">
            <i class="ki ki-bold-more-hor"></i>
          </div>
        </div>
        <!-- 
        <div v-if="row.item.operation_type == 'T'" class="symbol symbol-45 symbol-light symbol-shadow  mt-1 ml-5">
          <span class="symbol-label">
            <div
              v-if="JSON.parse(row.item.object_from_to).avatar"
              class="symbol-label"
              :style="'background-image:url(' + JSON.parse(row.item.object_from_to).avatar + ')'"
            ></div>
            <div v-else class="symbol-label" style="background-image:url(/media/users/blank.png)"></div>
          </span>
        </div> -->
      </template>

      <template #cell(value)="row">
        <span
          class="font-weight-bolder d-block font-size-lg text-right"
          :class="row.item.effect == 'C' ? 'text-success' : 'text-danger'"
        >
          {{ row.item.value | currency('') }}
        </span>
      </template>

      <template #cell(balance)="row">
        <span class="text-dark-75 font-weight-bolder d-block font-size-lg text-right">
          {{ row.item.balance | currency('', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </span>
      </template>

      <template v-if="!isLoading && showFooter && extractSnail" v-slot:custom-foot>
        <b-tr variant="secondary">
          <b-td></b-td>
          <b-td></b-td>
          <b-td></b-td>
          <b-td class="text-dark-75 font-weight-bolder text-right">Saldo</b-td>
          <b-td class="text-dark-75 font-weight-bolder d-block font-size-lg text-right">
            {{ currentWalletSnail.balance | currency('SN$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
          </b-td>
        </b-tr>
      </template>
    </b-table>

    <pagination
      v-if="!isLoading && extractSnail.length > 0 && isPagination"
      :items="extractSnail"
      @changePage="onChangePage"
    ></pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pagination from '@/shared/content/widgets/table/Pagination.vue';
import EmptyTable from '@/shared/components/extras/EmptyTable';
import BusyTable from '@/shared/components/extras/BusyTable';

export default {
  components: {
    Pagination,
    EmptyTable,
    BusyTable
  },
  props: {
    limit: {
      type: Number,
      default: 0
    },
    isPagination: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { label: 'Data', key: 'date', sortable: false },
        { label: 'Tipo de movimentação', key: 'type', sortable: false },
        { label: 'Usuário', key: 'object_from_to', sortable: false },
        { label: 'Valor', key: 'value', sortable: false, thClass: 'text-right' },
        { label: 'Saldo', key: 'balance', sortable: false, thClass: 'text-right' }
      ],
      pageOfItems: []
    };
  },
  computed: {
    ...mapGetters(['extractSnail', 'currentWalletSnail'])
  },
  async mounted() {
    this.isLoading = true;

    await this.$store.dispatch('GET_EXTRACT_SNAILCOIN', this.limit).catch(() => {
      this.pageOfItems = null;
    });

    if (!this.isPagination) {
      this.pageOfItems = this.extractSnail;
    }

    this.isLoading = false;
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  }
};
</script>

<style></style>
